
<template>
    <div id="component">
        <div class="filter-panel">
            <input
                type="text"
                placeholder="搜索巡检点"
                v-model="queryParams.keyword"
                class="cs-input"
            />
            <template v-if="[1001, 1002].includes(Number(staffInfo.dutyType))">
                <div class="cs-select" style="margin-right: 30px">
                    <select v-model="queryParams.orgId">
                        <option value="">全部部门</option>
                        <option
                            v-for="org in orgList"
                            :key="org.code"
                            :value="org.code"
                        >
                            {{ org.name }}
                        </option>
                    </select>
                    <i class="icon el-icon-caret-bottom"></i>
                </div>
            </template>
            <button
                type="button"
                class="btn btn-primary btn-sm"
                v-on:click="_queryInspectionPointMethod()"
            >
                查询
            </button>
        </div>

        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <button
                            type="button"
                            class="btn btn-primary btn-sm sticky-right"
                            @click="_openAddInspectionPointModal()"
                        >
                            <svg
                                class="icon"
                                aria-hidden="true"
                            >
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            添加
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th class="text-center">创建时间</th>
                        <th class="text-center">巡检点</th>
                        <th class="text-center">所属部门</th>
                        <th class="text-center">位置</th>
                        <th class="text-center">iBeacon UUID</th>
                        <th class="text-center">签到</th>
                        <th class="text-center">签到方式</th>
                        <th class="text-center">检查项</th>
                        <th class="text-center">操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr
                        v-for="(
                                inspectionPoint, index
                            ) in inspectionPointManageInfo.inspectionPoints"
                        :key="index"
                    >
                        <td class="text-center date-td">
                            {{ inspectionPoint.createTime }}
                        </td>
                        <td class="text-center">
                            <div
                                class="btn btn-link btn-content"
                                @click="openViewModal(inspectionPoint.name)"
                            >
                                {{ inspectionPoint.name }}
                            </div>
                        </td>
                        <td class="text-center">
                            {{ inspectionPoint.orgName }}
                        </td>
                        <td class="text-center">
                            {{ inspectionPoint.addr }}
                        </td>
                        <td class="text-center">
                            <div
                                class="btn btn-link btn-content"
                                @click="
                                        openViewModal(inspectionPoint.beaconId, 1 , '查看iBeacon UUID')
                                    "
                            >
                                {{
                                    (
                                        inspectionPoint.beaconId - 0
                                    ).toString() == "NaN"
                                        ? inspectionPoint.beaconId
                                        : '-'
                                }}
                            </div>
                        </td>
                        <td class="text-center">
                            {{
                                inspectionPoint.checkinMethod &&
                                inspectionPoint.checkinMethod != 0
                                    ? "需要"
                                    : "不需要"
                            }}
                        </td>
                        <td class="text-center">
                            {{
                                checkinMethodStr[
                                    inspectionPoint.checkinMethod
                                    ] || '-'
                            }}
                        </td>
                        <td class="text-center">
                            <template
                                v-if="inspectionPoint.inspectItemCount > 0"
                            >
                                <div
                                    class="btn btn-link"
                                    @click="
                                            openViewModal(
                                                inspectionPoint.inspectItems,
                                                2
                                            )
                                        "
                                >
                                    {{ inspectionPoint.inspectItemCount }}
                                </div>
                            </template>
                            <template v-else>-</template>
                        </td>
                        <td class="text-center">
                            <template
                                v-if="staffInfo && staffInfo.jobLevel == 1"
                            >
                                <div
                                    class="btn-group"
                                >
                                    <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        操作
                                    </button>
                                    <ul
                                        class="dropdown-menu"
                                        style="min-width: 76px; width: 130px;"
                                    >
                                        <li
                                            @click="openInspectPointModel(inspectionPoint)"
                                        >
                                            <a
                                                style="width: 100%;"
                                            >设置签到/检查项</a>
                                        </li>
                                        <li @click="_openEditInspectionPointModel(inspectionPoint)">
                                            <a style="width: 100%;">修改</a>
                                        </li>
                                        <li
                                            @click="_openDeleteInspectionPointModel(inspectionPoint)"
                                        >
                                            <a
                                                style="width: 100%;"
                                            >删除</a>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination />
        </div>

        <ViewModal name="viewModal" componentName="ViewModal"></ViewModal>

        <AddInspectionPoint />

        <AddInspectionItem
            name="addInspectionItem"
            componentName="AddInspectionItem"
            callBackListener="inspectionPointManage"
            callBackFunction="addInspectItem"
        ></AddInspectionItem>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import ViewModal from "@/components/ViewModal.vue";
import AddInspectionPoint from "@/components/AddInspectionPoint.vue";
import AddInspectionItem from "@/components/AddInspectionItem.vue";

const DEFAULT_PAGE = 1;
const DEFAULT_ROWS = 10;

import {
    createInspectionItemUrl,
    editInspectionPointUrl,
    createInspectionPointUrl,
    queryDepartmentUrl,
    queryInspectionPointUrl,
    downloadFileOssDomain, disableInspectPointUrl
} from "@/requestUrl";
import { ORG_LEVEL } from "@/constant";
import CSTable from "@/components/common/CSTable";
export default {
    props: {},
    components: {
        CSTable,
        Pagination,
        ViewModal,
        AddInspectionPoint,
        AddInspectionItem,
    },
    data() {
        return {
            filterHeight: 0,
            downloadFileOssDomain: downloadFileOssDomain,
            userInfo: this.$vc.getCurrentStaffInfo(),
            checkinMethodStr: { 1: "定位自动签到", 2: "拍照签到" },
            staffInfo: this.$vc.getCurrentStaffInfo(),
            orgList: [],
            queryParams: {
                regionId: this.$vc.getCurrentRegion().communityId,
                buildingId: "",
                keyword: "",
                orgId: this.$vc.getCurrentStaffInfo().orgId,
                pageNum: 1,
                pageSize: 10,
            },
            inspectionPointManageInfo: {
                inspectionPoints: [],
                total: 0,
                records: 1,
                moreCondition: false,
                inspectionName: "",
                conditions: {
                    inspectionId: "",
                    machineId: "",
                    inspectionName: "",
                    machineCode: "",
                },
            },
        };
    },
    mounted() {
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    created() {
        if (
            [1001, 1002].includes(
                Number(this.$vc.getCurrentStaffInfo().dutyType)
            )
        ) {
            this.queryParams.orgId = "";
        }
        this.getOrgList();
        this._listInspectionPoints(DEFAULT_PAGE, DEFAULT_ROWS);
        this.$vc.on(this.$route.path, "inspectionPointManage", "listInspectionPoint", (_param) => {
                this._listInspectionPoints(this.queryParams.pageNum);
            }
        );
        this.$vc.on(this.$route.path, "inspectionPointManage", "addInspectItem", (params) => {
            this.saveInspectionItemListener(params);
        });
        this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
            this._listInspectionPoints(_currentPage, DEFAULT_ROWS);
        });
        window.addEventListener("keydown",this._queryInspectionPointMethodDown);
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this._queryInspectionPointMethodDown);
    },
    deactivated(){
         window.removeEventListener("keydown",this._queryInspectionPointMethodDown);
    },
    methods: {
        _queryInspectionPointMethodDown(e){
            if(e.keyCode == 13){
                this._queryInspectionPointMethod();
            }
        },
        openInspectPointModel(pointInfo) {
            this.$vc.emit(this.$route.path, "addInspectionItem", "notify", pointInfo);
        },
        openViewModal(viewInfo, type = 1, title) {
            let params = {};
            if (type === 1) {
                params.content = viewInfo;
                this.$CSDialog.alert({
                    title: title || '查看巡检点',
                    message: viewInfo,
                })
                return ;
            }
            if (type === 2) {
                let content = "";
                const inspectMethodMapping = {
                    1: "直接完成",
                    2: "拍照完成",
                };
                viewInfo.forEach(val => {
                    content += `<li class="list-group-item text-left">
                                    ${val.name}(完成方式：${inspectMethodMapping[val.inspectMethod]})
                                </li>`;
                });
                params.content = `<ul class="list-group" style="font-size: 20px;">${content}</ul>`;
                this.$CSDialog.alert({
                  title: '检查项',
                  messageHtml: params.content
                })
            }
        },
        getOrgList() {
            this.$fly
                .post(queryDepartmentUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    code: "",
                    name: "",
                    dutyType: "",
                    parentCode: "",
                    level: ORG_LEVEL.DEPARTMENT,
                    pageNo: 1,
                    pageSize: 100,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    console.log(res.data);
                    this.orgList = res.data;
                });
        },
        // 保存巡检项
        saveInspectionItemListener(params) {
            params.operatorId = this.userInfo.id;
            this.$fly.post(createInspectionItemUrl, params).then((res) => {
                if (res.code !== 0) {
                    return false;
                }
                this.$vc.toast("设置成功");
                this._listInspectionPoints(this.queryParams.pageNum);
            });
        },
        _listInspectionPoints: function (_page = 1, _rows = 10) {
            this.queryParams.pageNum = _page;
            this.queryParams.pageSize = _rows;
            this.queryParams.regionId = this.$vc.getCurrentRegion().communityId;
            this.queryParams.operatorId = this.userInfo.id;
            this.$fly
                .post(queryInspectionPointUrl, this.queryParams)
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.inspectionPointManageInfo.inspectionPoints = res.data.datas;
                    if (_page === 1) {
                      this.$vc.emit(this.$route.path, "pagination", "init", {
                        currentPage: _page,
                        pageSize: _rows,
                        total: res.data.total,
                      });
                    }
                });
        },
        _openAddInspectionPointModal: function () {
            this.$vc.emit(this.$route.path,
                "addInspectionPoint",
                "openAddInspectionPointModal",
                null
            );
        },
        _openEditInspectionPointModel: function (_inspectionPoint) {
            this.$vc.emit(this.$route.path,
                "addInspectionPoint",
                "openAddInspectionPointModal",
                _inspectionPoint
            );
        },
        _openDeleteInspectionPointModel: function (_inspectionPoint) {
            this.$CSDialog.confirm({
                title: '提示',
                message: '确定删除吗?',
                onConfirm: () => {
                    this.$fly
                        .post(disableInspectPointUrl, {
                            regionId: this.$vc.getCurrentRegion().communityId,
                            pointId: _inspectionPoint.id,
                            operatorId: this.$vc.getCurrentStaffInfo().id,
                        })
                        .then((res) => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.$vc.toast("删除成功");
                            this.$CSDialog.instance.closeDialog();
                            this._listInspectionPoints();
                        });
                }
            })
        },
        _queryInspectionPointMethod: function () {
            this._listInspectionPoints(DEFAULT_PAGE, DEFAULT_ROWS);
        },
        _moreCondition: function () {
            if (this.inspectionPointManageInfo.moreCondition) {
                this.inspectionPointManageInfo.moreCondition = false;
            } else {
                this.inspectionPointManageInfo.moreCondition = true;
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
.filter-panel
    .cs-input
        margin 0
        margin-right 20px
        margin-bottom 15px
a
    text-decoration none
    color black
    :hover
        color black
</style>>

