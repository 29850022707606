
<template>
  <div>
    <CSDialog dialog-title="添加检查项" :dialog-visible="dialogVisible" @onConfirm="saveInspectionItem"
      @onClose="closeDialog"
      dialogWidth="580px"
    >
      <template v-slot:dialog-content>
        <div class="ibox-content">
          <div class="form-group row" style="font-size: 24px;">
            <label style="width: 100px; text-align: right; margin-right: 40px;">巡检点</label>
            <span>{{ pointInfo.name }}</span>
          </div>
          <div class="form-group row" style="font-size: 24px;">
            <label style="width: 100px; text-align: right; margin-right: 40px;">签到</label>
              <input
                  type="radio"
                  name="sign"
                  :value="1"
                  v-model="needCheckIn"
                  style="margin-right: 10px; margin-top: 8px;"
              />
              <label style="margin-right: 20px;">
                  需要
              </label>
              <input
                  type="radio"
                  name="sign"
                  :value="0"
                  v-model="needCheckIn"
                  style="margin-top: 8px; margin-right: 10px;"
              />
              <label
              >
                不需要
              </label>
          </div>
          <template v-if="needCheckIn == 1">
            <div class="form-group row">
                <label style="width: 100px; text-align: right; margin-right: 40px;">签到方式</label>
                <input
                    type="radio"
                    name="signWay"
                    :value="1"
                    v-model="pointInfo.checkInMethod"
                    style="margin-right: 10px; margin-top: 8px;"
                />
                <label style="margin-right: 20px;">定位自动签到</label>
                <input
                    type="radio"
                    name="signWay"
                    :value="2"
                    v-model="pointInfo.checkInMethod"
                    style="margin-right: 10px; margin-top: 8px;"
                />
                <label>拍照签到</label>
            </div>
          </template>
          <div class="form-group row">
            <label style="width: 100px; text-align: right; margin-right: 40px;">检查项</label>
              <input
                  type="radio"
                  name="inspection"
                  :value="1"
                  v-model="needInspection"
                  style="margin-right: 10px; margin-top: 8px;"
              />
              <label style="margin-right: 20px;">有</label>
              <input
                  type="radio"
                  name="inspection"
                  :value="0"
                  v-model="needInspection"
                  style="margin-top: 8px; margin-right: 10px;"
              />
              <label style="margin-right: 10px;">无</label>
          </div>
          <template v-if="needInspection == 1">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label">&nbsp;</label>
              <div class="col-sm-10">
                <div class="inspection-group">
                  <div
                      class="inspection-item"
                      v-for="(item, key) in inspectItems"
                      :key="key"
                      style="margin-left: 40px;"
                  >
                    <div>
                      <input
                        type="text"
                        v-model="item.name"
                        placeholder="请输入"
                        style="border: 1px solid #999; border-radius: 4px; height: 40px; padding-left: 10px;"
                      />
                      <span style="margin-left: 20px;">
                        <svg
                            class="icon"
                            aria-hidden="true"
                            @click="removeInspectItems(key)"
                            style="line-height:36px; position: absolute; top: 7px;"
                        >
                          <use xlink:href="#icon-menushanchu"></use>
                        </svg>
                      </span>
                    </div>
                    <div>
                      <input
                          type="radio"
                          :value="1"
                          v-model="item.inspectMethod"
                          style="margin-right: 10px;"
                      />
                      <label style="margin-right: 20px;">完成</label>
                      <input
                          type="radio"
                          :value="2"
                          v-model="item.inspectMethod"
                          style="margin-right: 10px;"
                      />
                      <label>拍照完成</label>
                    </div>
                  </div>
                  <div>
                    <label style="margin-right: 40px;"></label>
                    <button
                        class="btn btn-primary"
                        style="width: 80px; height: 40px; line-height: 20px; border-radius: 6px;"
                        @click="addInspectItems"
                    >
                      添加
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </CSDialog>
  </div>
</template>
<script>
import CSDialog from "@/components/common/CSDialog";
export default {
  props: { callBackListener: String, callBackFunction: String },
  components: {CSDialog},
  data() {
    return {
      needCheckIn: "",
      needInspection: "",
      pointInfo: { pointId: "", checkInMethod: "", name: "", beaconId: "" },
      inspectItems: [],
      dialogVisible: false,
    };
  },

  mounted() {
    this.$vc.on(this.$route.path, "addInspectionItem", "changeModalStatus", () => {
      this.dialogVisible = true;
    });
    this.$vc.on(this.$route.path, "addInspectionItem", "notify", (_param) => {
      this.initInspectItems();
      this.dialogVisible = true;
      this.pointInfo = {
        pointId: _param.id,
        checkInMethod: _param.checkinMethod,
        name: _param.name,
        beaconId: _param.beaconId,
      };
      this.needCheckIn = _param.checkinMethod > 0  ? 1 : 0;
      this.inspectItems = [];
      if (_param.inspectItems && _param.inspectItems.length > 0) {
        this.needInspection =  1;
        _param.inspectItems.forEach(item => {
          this.inspectItems.push({
            pointId: _param.id,
            name: item.name,
            inspectMethod: item.inspectMethod,
          })
        })
      }
    });
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false;
    },
    addInspectItems() {
      this.inspectItems.push({
        pointId: this.pointInfo.pointId,
        name: "",
        inspectMethod: "",
      });
    },
    removeInspectItems(index) {
      this.inspectItems.splice(index, 1);
    },
    initInspectItems() {
      this.needCheckIn =  "";
      this.needInspection = "";
      this.pointInfo = { pointId: "", checkInMethod: "", name: "", beaconId: "" };
    },
    saveInspectionItem() {
      if (this.needCheckIn == 0) {
        this.pointInfo.checkInMethod = 0;
      }
      if (
        this.pointInfo.checkInMethod == 1 && (!this.pointInfo.beaconId || this.pointInfo.beaconId == "" || !Number.isNaN(+this.pointInfo.beaconId))) {
        this.$vc.message("iBeaconId不能为空");
        return;
      }
      if (this.needInspection == 0) {
        this.inspectItems = [];
      } else {
          if (this.inspectItems.some(item => !item.name || !item.inspectMethod)) {
              this.$vc.message("检查项内容请填写完整");
              return ;
          }
      }
      let params = { inspectItems: this.inspectItems, ...this.pointInfo };
      if (this.$vc.notNull(this.callBackListener)) {
        this.$vc.emit(this.$route.path, this.callBackListener, this.callBackFunction, params);
        this.dialogVisible = false;
        return;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.ibox-content
  padding 30px
  font-size 24px
  .btn
    font-size 24px
  .col-form-label
    text-align right
    padding 0 20px
.col-sm-10
  line-height 1
  display flex
  flex-flow row nowrap
  align-items center
  label
    display inline-flex
    flex-flow row nowrap
    align-items center
    margin-bottom 0
    &:not(:last-of-type)
      margin-right 15px
    input
      margin-right 5px
.inspection-item
  margin-bottom 15px
  position relative
  div
    display flex
    flex-flow row nowrap
    align-items center
    &:not(:last-of-type)
      margin-bottom 10px
    .glyphicon
      top 0
      margin-left 10px
      font-size 22px
      cursor pointer
    label
      display inline-flex
      flex-flow row nowrap
      align-items center


</style>
